import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutPrimary from "../templates/LayoutPrimary";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/base.scss";
import "../styles/components/header.module.scss";
import { imgBox } from "../styles/components/boxes.module.scss";
import {
  bannerBlock,
  bannerBlockHeadline,
  bannerBlockTagline,
  bannerBlockTitleSm,
} from "../styles/components/blocks.module.scss";
import {
  hideBlockSM,
  hideInlineSM,
} from "../styles/components/helpers.module.scss";
import ServicesBlock from "../components/blocks/ServicesBlock";
import AreasBlock from "../components/blocks/AreasBlock";
import DestinationsBlock from "../components/blocks/DestinationsBlock";

const IndexPage = () => {
  return (
    <LayoutPrimary>
      <Helmet>
        <title>
          Homepage: Unforgettable Five-Star Party Boat Charter for 12-Guests |
          Tampa Bay Boating Adventures
        </title>
        <meta
          name="description"
          content="Re-defining the Tampa party boat experience. 12-guest charter trips like you’ve never experienced before. Comfy boat, massive sound system, and captain included. This is how Tampa parties. Book today!"
        />
        <meta name="title" content="Re-defining the Tampa party boat experience. 12-guest charter trips like you’ve never experienced before. Comfy boat, massive sound system, and captain included. This is how Tampa parties. Book today!" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header className="container">
        <div className="row">
          <div className="col-xxs-12">
            <div className={bannerBlock}>
              <div className={imgBox}>
                <StaticImage
                  alt="Group of friends on a party boat near Tampa Bay, FL."
                  src="../images/banners/group-of-friends-on-party-boat.jpg"
                />
              </div>
              <div className={bannerBlockHeadline}>
                <h1 className={bannerBlockTagline}>
                  Party Boat Charters in Tampa Bay, FL
                </h1>
                <span className={bannerBlockTitleSm}>
                  Party on the Water <br className={hideBlockSM} />
                  in Tampa <span className={hideInlineSM}>Bay</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        <ServicesBlock />
        <AreasBlock />
        <DestinationsBlock />
      </main>
    </LayoutPrimary>
  );
};

export default IndexPage;
