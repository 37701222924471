import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  servicesBlock,
  gridBlock,
} from "../../styles/components/blocks.module.scss";
import {
  imgBox,
  txtBoxCenter,
  txtBoxRight,
  serviceBox,
} from "../../styles/components/boxes.module.scss";
import { btn } from "../../styles/components/buttons.module.scss";
import { h1, h2 } from "../../styles/components/titles.module.scss";
import FourHourBtn from "../buttons/FourHourBtn";
import SixHourBtn from "../buttons/SixHourBtn";
import EightHourBtn from "../buttons/EightHourBtn";
import SunsetHourBtn from "../buttons/SunsetHourBtn";
import { Link } from "gatsby";
import WaveRaveHourBtn from "../buttons/WaveRaveHourBtn";
import ExclusiveIslandOvernightBtn from "../buttons/ExclusiveIslandOvernightBtn";

const ServicesBlock = () => {
  return (
    <div className={servicesBlock}>
      <div className="row top-md this-pad-y-2-lg this-pad-y-1">
        <div className="col-md-7 col-xxs-12">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/CdszK83kZJ0"
            title="Partying The Day Away With TBBA | Downtown Tampa"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div className="col-md-5 col-xxs-12">
          <div className={txtBoxRight}>
            <h2 className={h1}>Private Boat Charters</h2>
            <p>We drive... you party!</p>
            <Link to="/services" className={btn}>
              View All Services
            </Link>

            <Link to="tel:8137428178" className={btn}>
              Call (813) 742-8178
            </Link>
          </div>
        </div>
      </div>
      <div className={gridBlock}>
        <div className="row center stretch pad-all-1-md pad-y-1">
          <div className="col-sm-6 col-xxs-12">
            <div className={serviceBox}>
              <div className={imgBox}>
                <StaticImage
                  src="../../images/banners/party-boat-off-island-beach.jpg"
                  alt="Party boat off Beer Can Island in Tampa Bay, FL."
                />
              </div>
              <div className="this-pad-x-2-md this-pad-x-1">
                <div className={txtBoxCenter}>
                  <h3 className={h2}>
                    4-Hour <span>Party Boat Charter</span>
                  </h3>
                  <p>
                    Spend 4 hours on the water cruising the Tampa Bay, FL
                    sights.
                  </p>
                  <p>
                    <FourHourBtn />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xxs-12">
            <div className={serviceBox}>
              <div className={imgBox}>
                <StaticImage
                  src="../../images/banners/girls-raising-arms-in-water.jpg"
                  alt="Girls raising their arms in the water near a private beach in Tampa Bay, FL."
                />
              </div>
              <div className="this-pad-x-2-md this-pad-x-1">
                <div className={txtBoxCenter}>
                  <h3 className={h2}>
                    6-Hour <span>Party Boat Charter</span>
                  </h3>
                  <p>
                    Explore sandbars, private beaches, and more on the 6-hour
                    party boat charter.
                  </p>
                  <p>
                    <SixHourBtn />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xxs-12">
            <div className={serviceBox}>
              <div className={imgBox}>
                <StaticImage
                  src="../../images/banners/young-friends-all-smiling-on-party-boat.jpg"
                  alt="Young friends smiling on a party boat near Downtown Tampa, FL."
                />
              </div>
              <div className="this-pad-x-2-md this-pad-x-1">
                <div className={txtBoxCenter}>
                  <h3 className={h2}>
                    8-Hour <span>Party Boat Charter</span>
                  </h3>
                  <p>
                    Explore Beer Can Island, Tampa Riverwalk, Armature Works,
                    and more.
                  </p>
                  <p>
                    <EightHourBtn />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xxs-12">
            <div className={serviceBox}>
              <div className={imgBox}>
                <StaticImage
                  src="../../images/banners/beautiful-sunset-off-tampa-coast.jpg"
                  alt="Beautiful sunset off the Tampa, FL coastline."
                />
              </div>
              <div className="this-pad-x-2-md this-pad-x-1">
                <div className={txtBoxCenter}>
                  <h3 className={h2}>
                    Sunset <span>Party Boat Charter</span>
                  </h3>
                  <p>
                    Relax with a beautiful sunset on a party boat in Tampa Bay,
                    Florida.
                  </p>
                  <p>
                    <SunsetHourBtn />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xxs-12">
            <div className={serviceBox}>
              <div className={imgBox}>
                <StaticImage
                  src="../../images/services/wave-rave-charter-group-home-page.jpg"
                  alt="Beautiful sunset off the Tampa, FL coastline."
                />
              </div>
              <div className="this-pad-x-2-md this-pad-x-1">
                <div className={txtBoxCenter}>
                  <h3 className={h2}>
                  Wave Rave Charter<span>Personal DJ'd Party</span>
                  </h3>
                  <p>
                  Party all night in Tampa with a personal, curated DJ set.
                  </p>
                  <p>
                    <WaveRaveHourBtn />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xxs-12">
            <div className={serviceBox}>
              <div className={imgBox}>
                <StaticImage
                  src="../../images/services/island-overnight-party-charter.jpg"
                  alt="Beautiful sunset off the Tampa, FL coastline."
                />
              </div>
              <div className="this-pad-x-2-md this-pad-x-1">
                <div className={txtBoxCenter}>
                  <h3 className={h2}>
                  Island Party<span>
                  Exclusive Island Charter</span>
                  </h3>
                  <p>
                  Party all day AND night on a catered, private island in Tampa Bay
                  </p>
                  <p>
                    <ExclusiveIslandOvernightBtn />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesBlock;
