import * as React from 'react'
import { destinationsBlock, gridBlock } from '../../styles/components/blocks.module.scss'
import { imgBox, txtBoxDestination, txtBoxRight, destinationBox } from '../../styles/components/boxes.module.scss'
import { h1, h3 } from '../../styles/components/titles.module.scss'
import { btn } from '../../styles/components/buttons.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import BuyBtn from '../buttons/BuyBtn'
import { Link } from "gatsby";
const DestinationsBlock = () => {
  return (
    <div className={destinationsBlock}>
      <div className='row top-md this-pad-y-2-lg this-pad-y-1'>
        <div className='col-md-7 col-xxs-12'>
          <div className={imgBox}>
            <StaticImage
              src="../../images/banners/private-beach-with-water-slide-and-boats.jpg"
              alt="Private beach with water slide and boats near Beer Can Island, FL."
            />
          </div>
        </div>
        <div className='col-md-5 col-xxs-12'>
          <div className={txtBoxRight}>
            <h2 className={h1}>Party Boat Destinations</h2>
            <p>Florida destinations, party boat experience.</p>
            <BuyBtn />
            <Link to="tel:8137428178" className={btn}>
              Call (813) 742-8178
            </Link>
          </div>
        </div>
      </div>
      <div className={gridBlock}>
        <div className='row stretch pad-all-2-md pad-y-1'>
          <div className='col-lg-4 col-sm-6 col-xxs-12'>
            <div className={destinationBox}>
              <div className={txtBoxDestination}>
                <h3 className={h3}>Beer <br />Can Island <span>Party Boat Destination</span></h3>
                <p>Explore the famous Beer Can Island location on our private party boat charters.</p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-sm-6 col-xxs-12'>
            <div className={destinationBox}>
              <div className={txtBoxDestination}>
                <h3 className={h3}>Private <br />Beaches <span>Party Boat Destination</span></h3>
                <p>Cruise up and enjoy the many private beaches in the Tampa Bay, FL area.</p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-sm-6 col-xxs-12'>
            <div className={destinationBox}>
              <div className={txtBoxDestination}>
                <h3 className={h3}>Hillsborough <br />Riverwalk <span>Party Boat Destination</span></h3>
                <p>See the sights at the Hillsborough Riverwalk destination in the Tampa area.</p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-sm-6 col-xxs-12'>
            <div className={destinationBox}>
              <div className={txtBoxDestination}>
                <h3 className={h3}>Armature <br />Works <span>Party Boat Destination</span></h3>
                <p>See one of the hottest spots in the Tampa, Florida at Armature Works.</p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-sm-6 col-xxs-12'>
            <div className={destinationBox}>
              <div className={txtBoxDestination}>
                <h3 className={h3}>Sparkman <br />Wharf <span>Party Boat Destination</span></h3>
                <p>Hang out at the infamous Sparkman Wharf location in the Tampa Bay, FL area.</p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-sm-6 col-xxs-12'>
            <div className={destinationBox}>
              <div className={txtBoxDestination}>
                <h3 className={h3}>More Destinations <br />Coming Soon <span>in the Tampa Bay, FL Area</span></h3>
                <p>Tampa Bay Boating Adventures is adding new destinations to our charters soon!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DestinationsBlock