import * as React from 'react'
import { app } from '../styles/templates/layout.module.scss'
import { Helmet } from 'react-helmet'
import Nav from '../components/global/Nav'
import Footer from '../components/global/Footer'
import '../styles/base.scss'

const LayoutPrimary = ({ children }) => {
  return (
    <div className={app}>
      <Helmet>
        {/* <script src="//scripts.iconnode.com/99818.js"></script> */}
      </Helmet>
      <Nav />
      {children}
      <Footer />
    </div>
  )
}

export default LayoutPrimary