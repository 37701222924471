import * as React from 'react'
import { areasBlock, gridBlock } from '../../styles/components/blocks.module.scss'
import { h1, sub } from '../../styles/components/titles.module.scss'
import { areasList } from '../../styles/components/lists.module.scss'

const AreasBlock = () => {
  return (
    <div className={areasBlock}>
      <div className={gridBlock}>
        <div className='row center this-pad-y-4-lg this-pad-all-2-md this-pad-y-1'>
          <div className='col-xxs-12'>
            <div className={h1}><span className={sub}>Book Party Boat Charters in These</span> Tampa, FL Locations</div>
            <ul className={areasList}>
              <li>Tampa</li>
              <li>Davis Island</li>
              <li>Downtown Tampa</li>
              <li>University of Tampa</li>
              <li>Channelside District</li>
              <li>Hyde Park</li>
              <li>Ybor</li>
              <li>South Tampa</li>
              <li>Harbour Island</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AreasBlock